<template>
  <div class="proposal__wrap">
    <div class="section shadow filter__wrap">
      <el-button class="addBtn theneBtn" type="primary" icon="el-icon-plus" @click="showEdit">添加轮播图</el-button>
    </div>
    <!-- 表格 -->
    <div class="table__wrap">
      <VTable
        has-pagionation
        title=''
        addText='添加'
        :field="field"
        :loading="table.loading"
        :data="table.data"
        :page='table.params.page'
        :pageSize='table.params.count'
        :total='table.total'
        :tree-props="{children: 'children', hasChildren: 'has_child'}"
        :hasPagionation="true"
        :default-expand-all="true"
        :showOverflowTooltip="true"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      >
        <template v-slot:image="{row}">
          <el-image 
            style="width: 100px; height: 50px"
            :src="row.image" 
            :preview-src-list="[row.image]"
            fit="cover">
          </el-image>
        </template>
        <template v-slot:status="{row}">
          <el-switch
            v-model="row.status"
            active-color="#409eff"
            inactive-color="#C5C5C5"
            :active-value="1"
            :inactive-value="2"
            @change="handleStatus(row)">
          </el-switch>
        </template>
        <template v-slot:action="{row}">
          <el-button type="text" icon="el-icon-edit" @click="showEdit(row)">编辑</el-button>
          <el-button class="delBtn" type="text" icon="el-icon-delete" @click="remove(row)">删除</el-button>
        </template>
      </VTable>
    </div>

    <edit ref="edit" @refresh="getTable"></edit>
    
  </div>
</template>

<script>
import VTable from '@/components/VTable';
import Edit from './components/Edit.vue'
export default {
  name: 'ArticleList',
  components: {
    VTable,
    Edit,
  },
  data() {
    return {
      field: [
        { name: "id", label: "ID", width:"60", hidden: false },
        { name: "image", label: "轮播图图片",  width: "160", hidden: false },
        { name: "link", label: "轮播图链接",  width: "500", hidden: false },
        { name: "status", label: "轮播图显示", hidden: false },
        { name: "created_at", label: "发布时间", hidden: false },
        { name: "action", label: "操作", fixed:"right", width: "180", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      this.$http.get('/admin/banner/list', { params:this.table.params }).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      if (!!row.id) {
        dom.getDetail(row)
      }
      dom = null
    },
    // 查看
    showDetail(row) {
      let dom = this.$refs.detail
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 重置
    reset() {
      this.$refs.elFormDom.resetFields()
      this.getTable();
    },
    // pageSize 改变时会触发
    handleSizeChange(pageSize) {
      this.table.params.count = pageSize;
      this.table.params.page = 1;
      this.getTable();
    },
    handleCurrentChange(page) {
      this.table.params.page = page;
      this.getTable();
    },
    // 删除
    remove(row) {
      this.$confirm("是否删除此条记录？", "删除", {
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            this.delete(row.id);
            done()
          } else {
            done()
          }
        }
      }).catch(() => {})
    },
    delete(rowid) {
      this.$http.post('/admin/banner/delete', { id: rowid }).then(res => {
        if(res.code === 1) {
          this.$message.success('操作成功！')
          this.getTable();
        } else {
          this.$message.error(res.data)
        }
      })
    },
    handleStatus(row) {
      let str = row.status === 1 ? '显示' : '隐藏'
      let _params = {
        id: row.id,
        status: row.status
      }
      this.$http.post('/admin/banner/updateStatus', _params).then(res => {
        if(res.code ===1) {
          this.getTable();
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .proposal__wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px;
    position: relative;

    .filter__wrap {
      padding: 10px;
      border-radius: 5px 5px 0 0;
    }

    .table__wrap {
      flex: 1;
      padding: 0;
      box-sizing: border-box;
    }
    .addBtn {
      margin-top: 20px;
    }
    .theneBtn {
      background-color: $theme !important;
      border-color: $theme !important;
    }
  }
</style>