<template>
  <div class="addPerson__wrap" v-show="visible">
    <el-button style="margin:20px;" plain icon="el-icon-d-arrow-left" @click="toggle(false)">返回</el-button>
    <div class="main">
      <el-form
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom'
        label-width='110px'
        label-position='top'
        :model='form.data'
        :rules='form.rules'
      >
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="轮播图显示:" prop="status">
              <el-radio-group v-model="form.data.status">
                <el-radio :label="1">显示</el-radio>
                <el-radio :label="2">不显示</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="image" label="轮播图图片" ref="elUpload">
              <el-upload
                class="avatar-uploader"
                action=""
                :headers="{ token: token }"
                :show-file-list="false"
                :before-upload="beforeAvatarUpload"
                :http-request="httpRequest"
                accept="image/jpeg, image/png"
                >
                <img v-if="this.form.data.image" :src="this.form.data.image" class="avatar" style="object-fit: contain;">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <div class="el-upload__tip" slot="tip">
                   （请上传尺寸为1920*700px，jpg/png格式的图片，图片不能超过2M
                </div>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="link" label="轮播图链接">
              <el-input placeholder="请输入轮播图点击跳转的链接" v-model="form.data.link" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="end">
          <el-button @click='clean'>重置</el-button>
          <el-button class="theneBtn" type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
  import common from '@/util'
  import {getStorage} from '@/storage'
  import MyEditor from "@/components/MyEditor.vue";
  export default {
    name: 'NotifyEdit',
    components: {
      MyEditor,
    },
    data() {
      return {
        token: getStorage('token'),
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            status: 1,
            image: '',
            link: '',
          },
          rules: {
            status: [{ required: true, message: '请选择状态', trigger: 'change' }],
            image: [{ required: true, message: '请上传图片', trigger: 'change' }],
            // link: [{ required: true, message: '请输入链接', trigger: 'change' }],
          }
        },
      }
    },
    methods: {
      // 获取详情
      getDetail(row) {
        this.isChange = true
        this.$http.get('/admin/banner/detail', {params:{id: row.id} }).then(res => {
          if(res.code === 1) {
            common.deepClone(this.form.data, res.data)
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
           this.form.loading = true;
            console.log(this.form.data)
           let apiUrl = !this.isChange ? '/admin/banner/create' : '/admin/banner/edit'
            this.$http.post(apiUrl, this.form.data).then(res => {
              if(res.code === 1) {
                this.toggle(false);
                this.$emit('refresh')
              } else {
                this.$message.error(res.msg)
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
      beforeAvatarUpload(file) {
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          this.$message.error('上传文件大小不能超过 2MB!');
        }
        return isLt2M;
      },
      httpRequest(file) {
        let formData = new FormData();
        formData.append('file', file.file);
        this.$http.post('/common/qiniu/upload', {formData, type:'upload'}).then(res => {
          if(res.code === 1) {
            this.form.data.image = res.data.all_path_url;
            this.$refs.elUpload.clearValidate()
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      // 重置
      clean() {
        this.$refs.elFormDom.resetFields()
        this.form.data.content = '';
      }
    }
  }
</script>
<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
  background-color: #fff;
  margin: 20px;
  z-index: 5 !important;
  overflow: scroll;
  .main {
    margin: 0 20px;
  }
  .theneBtn {
    background-color: $theme !important;
    border-color: $theme !important;
  }
  .el-upload__tip {
    color:#b42020;
    margin-top: 0;
  }
}
</style>